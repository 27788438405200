import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaGithub } from 'react-icons/fa';
import { useIntersectionObserver } from './useIntersectionObserver';
import { FooterProps } from './types';

  
const Footer = forwardRef<HTMLElement, FooterProps>(({ currentSection, totalSections, scrollDirection, id }, ref) => {
  const [isIntersecting] = useIntersectionObserver({ threshold: 0.5 });

  return (
    <motion.footer
      ref={ref}
      id={id}
      initial={{ opacity: 0, y: scrollDirection === 'down' ? 50 : -50 }}
      animate={{ opacity: isIntersecting ? 1 : 0, y: 0 }}
      exit={{ opacity: 0, y: scrollDirection === 'down' ? -50 : 50 }}
      transition={{ duration: 0.5 }}
      className="relative h-screen flex items-center justify-center snap-start z-20"
    >

    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 justify-between items-center">
        <div className="">
            <img 
                src="/LogoWhiteTransparent.svg" 
                className="h-24 mx-auto fade-in"
                alt="Logo"
            />
        </div>
        <div className="text-3xl font-medium relative w-full items-center text-center">
            Get project updates
        </div>
        <div className="flex space-x-4 items-center mx-auto mt-8 w-full items-center justify-center">
          <a href="https://x.com/AdXso_" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="w-12 h-12" />
          </a>
          <a href="https://github.com/dax-protocol" target="_blank" rel="noopener noreferrer">
            <FaGithub className="w-12 h-12" />
          </a>
        </div>
        <div className="mt-20 text-md opacity-7 text-center md:text-left">
            © {new Date().getFullYear()} Quanteon Limited. <br className="md:hidden"/> All rights reserved.
        </div>
      </div>

    </motion.footer>
  );
});

export default Footer;